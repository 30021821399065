import { getSettingsProcedures } from '@/services/Procedure'

export const state = () => ({
  settings: {},
})

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = JSON.parse(JSON.stringify(settings))
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getSettingsProcedures(this.$fire)
      let settings = {}
      if (result.exists) {
        settings = { ...result.data() }
      }
      commit('SET_SETTINGS', settings)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getSettingsProcedures(state) {
    return state.settings
  },
}
