import { setDocFirestore } from '@/services/firebaseWrite'

// Accessing the 'settings' document in the 'settings' collection and getting its data
export const getSettings = async ($fire: any) => {
  return await $fire.firestore.collection('settings').doc('settings').get()
}

// Function to update the settings document in Firestore
export const updateSettings = async ($fire: any, body: any) => {
  return await setDocFirestore($fire, `settings/settings`, { ...body }, true)
}

// Fetching all documents from the 'settings' collection
export const getAllSettings = async ($fire: any) => {
  return await $fire.firestore.collection('settings').get()
}
