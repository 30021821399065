import { split, cloneDeepWith, isObject, isString, isUndefined } from 'lodash'

// Cleans the input object by removing undefined values and stripping tab characters from strings.
export const cleanData = (obj: any) => {
  const result = cloneDeepWith(obj, function (v: any) {
    if (!isObject(v) && isUndefined(v)) {
      return null
    } else if (!isObject(v) && isString(v)) {
      return v.replace(/\t/g, '')
    }
  })

  return result
}

// Sets data to a Firestore document or collection with an optional merge option.
export const setDocFirestore = async (
  $fire: any,
  path: string,
  data: object,
  mergeOption: boolean
) => {
  const numSegments = split(path, '/').filter(
    (segment: string) => segment !== ''
  )
  const selectedMergeOption =
    mergeOption !== undefined && mergeOption !== null ? mergeOption : false

  const cleanedData = cleanData(data)

  if (numSegments.length % 2 === 0) {
    return await $fire.firestore
      .doc(path)
      .set({ ...cleanedData }, { merge: selectedMergeOption })
  } else {
    return await $fire.firestore.collection(path).add({ ...cleanedData })
  }
}

// Updates an existing Firestore document with the cleaned data.
export const updateDocFirestore = async (
  $fire: any,
  path: string,
  data: object
) => {
  const cleanedData = cleanData(data)
  if (cleanedData?.originalUser) {
    delete cleanedData.originalUser
  }
  return await $fire.firestore.doc(path).update({ ...cleanedData })
}
