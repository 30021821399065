
import swal from 'sweetalert'

import SendEmailVerificationMixin from '@/mixins/SendEmailVerificationMixin'
import { getSettings } from '@/services/Settings'

declare global {
  interface Window {
    recaptchaContactFooter: any
    recaptchaWidgetContactFooter: any
  }
}

export default SendEmailVerificationMixin.extend({
  name: 'Contact',
  components: {},
  props: {},
  data() {
    return {
      contact: {
        name: '',
        email: '',
        message: '',
      },
      email_contact: '',
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
      required: [(v: any) => !!v || this.$t('fieldRequired')],
      rulesEmail: [
        (v: string) => !!v || this.$t('fieldRequired'),
        (v: string) =>
          !v ||
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(v) ||
          this.$t('emailValid'),
      ],
      loading: false,
      reCAPTCHA: false,
      valid: false,
    }
  },
  mounted() {
    this.getSettings()
    const self = this
    window.recaptchaContactFooter = new self.$fireModule.auth.RecaptchaVerifier(
      'recaptcha-contact-footer',
      {
        callback() {
          self.reCAPTCHA = true
        },
        'expired-callback'() {
          self.reCAPTCHA = false
        },
        size: self.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal',
      }
    )
    window.recaptchaContactFooter.render().then(function (widgetId: any) {
      window.recaptchaWidgetContactFooter = widgetId
    })
  },
  methods: {
    async getSettings(): Promise<void> {
      const result = await getSettings(this.$fire)
      if (result.exists) {
        const data = {
          ...result.data(),
          id: result.id,
        }
        this.email_contact = data.email_contact
      }
    },
    async sendEmailContact(): Promise<void> {
      this.loading = true
      try {
        await this.sendEmail(
          this.email_contact,
          `${this.$t('email-contact')}`,
          `${this.contact.name} (${this.contact.email}) ${this.$t(
            'contact-email-subject'
          )}:`,
          `mailto: ${this.contact.email}`,
          `${this.contact.message}`,
          '',
          '',
          ''
        )
        this.contact.name = ''
        this.contact.email = ''
        this.contact.message = ''
        swal({
          title: `${this.$t('email-sent')}`,
          text: `${this.$t('will-contact')}`,
          icon: 'success',
          buttons: `${this.$t('close')}`,
        })
      } catch (error) {
        swal({
          title: `${this.$t('email-failed')}`,
          text: `${this.$t('try-again')}`,
          icon: 'error',
          buttons: `${this.$t('close')}`,
        })
      }
      this.valid = false
      this.loading = false
    },
  },
})
