import * as _ from 'lodash'
import { getAllPaymentMethods } from '@/services/Sales'

export const state = () => ({
  paymentMethods: [],
})

export const mutations = {
  SET_PAYMENTMETHODS(state, paymentMethods) {
    state.paymentMethods = _.cloneDeep(paymentMethods)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllPaymentMethods(this.$fire)
      const paymentMethods = []
      if (!result.empty) {
        result.forEach((doc) => {
          paymentMethods.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_PAYMENTMETHODS', paymentMethods)
    } catch (error) {
      // @todo Handle Error
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getActivePaymentMethods(state) {
    const activePaymentMethods = state.paymentMethods.filter(
      (paymentMethod) => paymentMethod.active === true
    )
    return activePaymentMethods
  },
}
