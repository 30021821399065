import firebase from 'firebase/compat/app'

// Function to get the settings for procedures from Firestore
export const getSettingsProcedures = async ($fire: any) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .doc('settings')
    .get()
}

// Function to save procedure ratings to Firestore
export const saveProcedureRating = async (
  $fire: any,
  path: string,
  body: any
) => {
  const data = { ...body }
  data.createdAt = firebase.firestore.Timestamp.now()
  return await $fire.firestore.collection(`${path}/ratings`).add(data)
}
