import kebabCase from 'lodash/kebabCase'
import split from 'lodash/split'
import map from 'lodash/map'
import join from 'lodash/join'

// Function to get the full name by combining first name, last name, and optional second last name.
export const getFullName = function (firstName, lastName, lastName2 = '') {
  if (firstName && lastName) {
    // If both first and last names are provided, combine them, adding the second last name if it exists
    return firstName + ' ' + lastName + (lastName2 ? ' ' + lastName2 : '')
  } else if (firstName) {
    // If only the first name is provided
    return firstName
  } else {
    // If no first name is provided
    return ''
  }
}

// Function to split an array into chunks of a specified size
export const sliceArrayIntoChunks = function (arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize) // Get a chunk of the array
    res.push(chunk)
  }
  return res
}

// Function to check if a token has expired by decoding and comparing the expiration time
export const isTokenExpired = (token) => {
  const decodedToken = JSON.parse(atob(token.split('.')[1])) // Decode the token's payload
  const expirationTime = decodedToken.exp // Get expiration time
  const currentTime = Math.floor(Date.now() / 1000) // Get current time in seconds

  return expirationTime < currentTime // If expiration time is less than current time, the token is expired
}

// Function to get the browser name based on the user agent string
export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent
  let browserName = 'Unknown'

  // Check the user agent string for specific browsers
  if (userAgent.includes('Firefox')) {
    browserName = 'Firefox'
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera'
  } else if (userAgent.includes('Trident')) {
    browserName = 'Internet Explorer'
  } else if (userAgent.includes('Edge')) {
    browserName = 'Edge'
  } else if (userAgent.includes('Chrome')) {
    browserName = 'Chrome'
  } else if (userAgent.includes('Safari')) {
    browserName = 'Safari'
  }

  return browserName
}

// Function to convert a mask (pattern) to a regular expression
export const maskToRegex = (mask) => {
  // Reserved characters for the mask (pattern)
  const maskCharacters = {
    '#': '[0-9]', // Number
    A: '[a-zA-Z]', // Alphabetic characters
    N: '[a-zA-Z0-9]', // Alphanumeric characters
    X: '\\S', // Non-whitespace characters
  }

  let regexPat = '^('
  for (const char of mask) {
    // If the character is a reserved mask character, replace it with the corresponding regex pattern
    // If the character is a special regex character, escape it
    // Otherwise, keep the character as it is
    if (maskCharacters[char]) {
      regexPat += maskCharacters[char]
    } else if ('^$\\.*+?()[]{}|'.includes(char)) {
      regexPat += '\\' + char
    } else {
      regexPat += char
    }
  }
  regexPat += ')?$'

  return new RegExp(regexPat)
}

// Function to check if an action is allowed based on user permissions and their role
export const actionIsAllowed = (action, permissions, role) => {
  let isAllowed = false
  const isAdminOrEditor = ['admin', 'editor'].includes(role) // Check if the role is admin or editor

  if (role === 'superadmin') {
    // If the role is superadmin, they can do any action
    isAllowed = true
  } else if (isAdminOrEditor && !permissions) {
    // If the user is admin or editor but has no specific permissions, allow all actions
    isAllowed = true
  } else if (isAdminOrEditor && permissions && permissions.length === 0) {
    // If the user is admin or editor but the permissions array is empty, allow all actions
    isAllowed = true
  } else if (isAdminOrEditor && permissions && permissions.length > 0) {
    // If the user has specific permissions, check if the action is allowed
    isAllowed = permissions.includes(action)
  } else {
    // For other cases, actions are allowed by default
    isAllowed = true
  }

  return isAllowed
}

// Custom function to convert a string to a snake-case format with a custom twist (e.g., kebab-case for each part of the string)
export const customSnakeCase = (s) => {
  return join(
    map(
      split(s, /(\d+)/), // Split the string into letters and numbers
      kebabCase // Apply kebabCase to each part
    ),
    '' // Join without additional separators
  )
}
