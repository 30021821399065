import { setDocFirestore } from '@/services/firebaseWrite'

// Function to get the landing text from Firestore
export const getLandingText = async ($fire: any) => {
  return await $fire.firestore.collection('settings').doc('landingText').get()
}

// Function to update the landing text in Firestore
export const updateLandingText = async ($fire: any, body: any) => {
  return await setDocFirestore($fire, `settings/landingText`, { ...body }, true)
}

// TODO: Should it be here?
// Function to get all certificates from Firestore
export const getCertificates = async ($fire: any) => {
  return await $fire.firestore
    .collection('certificates')
    .get()
    .then((doc: any) => {
      return doc
    })
}
