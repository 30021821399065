import Vue from 'vue'
import TiptapInput from '@/components/forms/CustomComponents/TiptapInput.vue'
import TiptapOutput from '@/components/forms/CustomComponents/TiptapOutput.vue'
import CustomFileInput from '@/components/forms/CustomComponents/CustomFileInput.vue'
import CustomIde from '@/components/forms/CustomComponents/CustomIde.vue'
import CustomInspectores from '@/components/forms/CustomComponents/CustomInspectores.vue'
import CustomAddressf from '@/components/forms/CustomComponents/CustomAddressf.vue'
import CustomPreventions from '@/components/forms/CustomComponents/CustomPreventions.vue'
import CustomGroupQuestions from '@/components/forms/CustomComponents/CustomGroupQuestions.vue'
import CustomApi from '@/components/forms/CustomComponents/CustomApi.vue'
import CustomInformeUrbanistico from '@/components/forms/CustomComponents/CustomInformeUrbanistico.vue'
import CustomMask from '@/components/forms/CustomComponents/CustomMask.vue'
import CustomSearch from '@/components/forms/CustomComponents/CustomSearch.vue'

const components = {
  TiptapInput,
  TiptapOutput,
  CustomFileInput,
  CustomIde,
  CustomInspectores,
  CustomAddressf,
  CustomPreventions,
  CustomGroupQuestions,
  CustomApi,
  CustomInformeUrbanistico,
  CustomMask,
  CustomSearch,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
Object.entries(components).forEach(([name, component]) => {
  Vue.component(component.name, component)
})
