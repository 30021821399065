/* eslint-disable no-console */
import { cloneDeep } from 'lodash'
import { getAllInspectorsConcepts } from '@/services/Inspectors'

export const state = () => ({
  inspectorsConcepts: [],
  inspectorsConfiguration: {
    filesSize: 3,
    anonymousInspection: false,
  },
})

export const mutations = {
  SET_INSPECTORS_CONCEPTS(state, inspectorsConcepts) {
    state.inspectorsConcepts = cloneDeep(inspectorsConcepts)
  },
  SET_INSPECTORS_CONFIG(state, inspectorsConfiguration) {
    state.inspectorsConfiguration = Object.assign({}, inspectorsConfiguration)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllInspectorsConcepts(this.$fire)
      const inspectorsConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          inspectorsConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_INSPECTORS_CONCEPTS', inspectorsConcepts)
    } catch (error) {
      // @todo Handle Error
      console.log(error)
      throw new Error(error)
    }
  },
  setConfiguration({ commit }, inspectorsConfiguration) {
    commit('SET_INSPECTORS_CONFIG', inspectorsConfiguration)
  },
}

export const getters = {}
