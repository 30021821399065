import * as _ from 'lodash'
import firebase from 'firebase/compat/app'
import { setDocFirestore } from '@/services/firebaseWrite'

// Adds multiple documents to a Firestore collection in a batch operation.
export const setCatalogs = async ($fire: any, docs: [], collection: string) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: any) => {
    doc.created = firebase.firestore.Timestamp.now()

    const docRef = $fire.firestore.collection(collection).doc()
    batch.set(docRef, doc)
  })
  return await batch.commit()
}

// Retrieves all catalogs from Firestore.
export const getCatalogs = async ($fire: any) => {
  return await $fire.firestore.collection('catalogs').get()
}

// Retrieves catalogs by module ID, filtering by 'modulesId' array.
export const getCatalogsByModule = async ($fire: any, moduleId: string) => {
  return await $fire.firestore
    .collection('catalogs')
    .where('modulesId', 'array-contains', moduleId)
    .get()
}

// Retrieves all terms from a specific catalog.
export const termsList = async ($fire: any, catalogID: string) => {
  return await $fire.firestore.collection(catalogID).get()
}

// Retrieves a specific catalog by its ID.
export const gatalog = async ($fire: any, catalogID: string) => {
  return await $fire.firestore.doc(catalogID).get()
}

// Updates the sublevel of a catalog.
export const updatesubCataloglevels = async (
  $fire: any,
  id: string,
  body: any
) => {
  return await setDocFirestore($fire, id, { ...body }, false)
}

// Saves a new catalog to Firestore and returns the catalog with its ID and path.
export const saveCatalog = async ($fire: any, catalog: any) => {
  const newCatalog = await $fire.firestore.collection('catalogs').doc()
  await setDocFirestore(
    $fire,
    `catalogs/${newCatalog.id}`,
    { ...catalog, id: newCatalog.id, path: newCatalog.path },
    false
  )

  return { ...catalog, id: newCatalog.id, path: newCatalog.path }
}

// Saves a new sublevel (catalog) to a specific collection and returns the updated catalog with its ID and path.
export const saveSublevel = async (
  $fire: any,
  collection: any,
  catalog: any
) => {
  const newSublevel = await $fire.firestore.collection(collection).doc()

  await setDocFirestore(
    $fire,
    `${collection}/${newSublevel.id}`,
    { ...catalog, id: newSublevel.id, path: newSublevel.path },
    false
  )

  return { ...catalog, id: newSublevel.id, path: newSublevel.path }
}

// Updates an existing catalog with new data.
export const updateCatalog = async ($fire: any, id: string, body: any) => {
  return await setDocFirestore($fire, `catalogs/${id}`, { ...body }, true)
}

// Updates the modules of a catalog, adding a new module or updating an existing one.
export const updateCatalogModules = async (
  $fire: any,
  id: string,
  body: any
) => {
  const currentCatalog = (await gatalog($fire, `catalogs/${id}`)).data()
  let modules = currentCatalog?.modules || []
  if (modules.length > 0) {
    modules = modules.map((moduleFromFb: any) => {
      return moduleFromFb.id === body.id ? { ...body } : { ...moduleFromFb }
    })
    modules.push(body)
  } else modules.push(body)
  modules = _.uniqWith(modules, _.isEqual)
  let modulesId = modules.map((module: any) => module.id)
  modulesId = _.uniqWith(modulesId, _.isEqual)
  return await setDocFirestore(
    $fire,
    `catalogs/${id}`,
    { modules: [...modules], modulesId, logChanges: false },
    true
  )
}

// Removes a module from a catalog and updates the catalog with the new module list.
export const updateCatalogModulesEmpty = async (
  $fire: any,
  catalogId: string,
  moduleToRemoveId: string
) => {
  const currentCatalog = (await gatalog($fire, `catalogs/${catalogId}`)).data()
  let modules = currentCatalog?.modules || []
  modules = modules.filter((module: any) => module.id !== moduleToRemoveId)
  const modulesId = modules.map((module: any) => module.id)
  return await $fire.firestore.collection('catalogs').doc(catalogId).set(
    {
      modules,
      modulesId,
      logChanges: false,
    },
    { merge: true }
  )
}

// Deletes a catalog by its ID.
export const deleteCatalog = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('catalogs').doc(id).delete()
}
