
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-mask',
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      userInput: '',
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    input(val) {
      if (val && this.obj.schema.disabled) {
        this.userInput = val
      }
    },
    obj(val) {
      if (val.value && val.schema.disabled) {
        this.userInput = val.value
      }
    },
  },
  mounted() {
    if (this.value) {
      this.userInput = this.value
    }
  },
  methods: {
    removeInput() {
      this.$emit('input', null)
    },
    sendValue(val) {
      this.$emit('input', val)
    },
  },
}
