// Function to get the configuration from Firestore
export const getConfiguration = async ($fire: any) => {
  return await $fire.firestore
    .collection('tools')
    .doc('inspectors')
    .collection('configuration')
    .get()
}

// Function to get all concepts related to inspectors
export const getAllInspectorsConcepts = async ($fire: any) => {
  return await $fire.firestore
    .collection('tools')
    .doc('inspectors')
    .collection('concepts')
    .get()
}

// Function to get only the concepts that are active (where 'active' is true)
export const getActivateInspectorsConcepts = async ($fire: any) => {
  const concepts = [] as any
  await $fire.firestore
    .collection('tools')
    .doc('inspectors')
    .collection('concepts')
    .where('active', '==', true)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(function (doc: any) {
        concepts.push({
          ...doc.data(),
          id: doc.id,
        })
      })
    })
  return concepts
}

// Function to delete an inspector concept by its ID
export const deleteInspectorsConcept = async ($fire: any, id: string) => {
  return await $fire.firestore
    .collection('tools')
    .doc('inspectors')
    .collection('concepts')
    .doc(id)
    .delete()
}
