export const state = () => ({
  preventionsConcepts: [],
  preventionsConfiguration: {
    filesSize: 3,
  },
})

export const mutations = {
  SET_PREVENTIONS_CONFIG(state, preventionsConfiguration) {
    state.preventionsConfiguration = Object.assign({}, preventionsConfiguration)
  },
}

export const actions = {
  setConfiguration({ commit }, preventionsConfiguration) {
    commit('SET_PREVENTIONS_CONFIG', preventionsConfiguration)
  },
}

export const getters = {
  getPreventionsConfig(state) {
    return state.preventionsConfiguration
  },
}
