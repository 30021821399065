
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-search',
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      options: [],
      filteredOptions: [], // Filtered options for auto-complete
      selectedPath: [], // Path selected
      searchQuery: '',
    }
  },
  async fetch() {
    await this.fetchJsonFromStorage()
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.searchQuery = this.setTextItem(val, true)
        this.selectedPath = [this.convertToTree(val)]
      }
    },
    searchQuery(val) {
      if (!val) {
        this.filteredOptions = [] // Clear the suggestions
        this.selectedPath = [] // Clear the path selected
      }
    },
  },
  async mounted() {
    if (this.value) {
      this.searchQuery = await this.setTextItem(this.value, true)
      this.selectedPath = [this.convertToTree(this.value)]
    }
  },
  methods: {
    // Filter options based on what the user types
    filterOptions() {
      const query = this.normalize(this.searchQuery)
      // Search all options with 'searchQuery' value in name key
      this.filteredOptions = this.options
        .filter((option) => {
          return Object.keys(option).some((key) => {
            return (
              key.startsWith('name') &&
              this.normalize(option[key]).includes(query)
            )
          })
        })
        .map((option) => {
          // Aquí resaltamos la palabra clave en cada opción
          const updatedOption = { ...option }
          Object.keys(updatedOption).forEach((key) => {
            if (
              key.startsWith('name') &&
              this.normalize(updatedOption[key]).includes(query)
            ) {
              updatedOption[key] = this.highlightMatch(
                updatedOption[key],
                query
              )
            }
          })
          return updatedOption
        })
    },
    // Función para resaltar las coincidencias
    highlightMatch(text, query) {
      if (!query) return text // Si no hay búsqueda, retorna el texto sin cambios

      // Normalizamos el texto y la consulta para manejar acentos y minúsculas
      const normalizedText = this.normalize(text)
      const normalizedQuery = this.normalize(query)

      // Creamos la expresión regular para encontrar las coincidencias exactas del query en el texto normalizado
      const regex = new RegExp(`(${normalizedQuery})`, 'gi')

      // Buscamos las coincidencias en el texto original, resaltando solo lo que coincida
      const highlightedText = normalizedText.replace(regex, (match) => {
        // console.log('match', match)
        return `<span class="highlight">${match}</span>`
      })
      // Obtener la palabra resaltada de la primera frase (sin el <span>)
      const highlightedWord = highlightedText
        .match(/<span class="highlight">([^<]+)<\/span>/)[1]
        .toLowerCase()

      // Buscar la posición de la palabra en la frase2
      const index = normalizedText.indexOf(highlightedWord)

      // Si encontramos la palabra, resaltar la palabra en la frase2
      if (index !== -1) {
        // Dividir la frase en partes antes y después de la palabra
        const before = text.slice(0, index)
        const after = text.slice(index + highlightedWord.length)

        // Crear el resultado resaltando la palabra encontrada
        return (
          before +
          `<span class="highlight">${text.slice(
            index,
            index + highlightedWord.length
          )}</span>` +
          after
        )
      }
      // Si no se encuentra la palabra, devolver la frase original
      return text
    },
    normalize(text) {
      if (text) {
        // We normalize the text, remove the accents and convert to lowercase
        return text
          .normalize('NFD')
          .replace(/[\u0300-\u036F]/g, '')
          .toLowerCase()
      }
      return ''
    },
    clarObject(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].replace(/<[^>]*>/g, '') // Remove html tags
        }
      }
      return obj
    },
    setTextItem(item, lastValue) {
      const textValue = Object.keys(item)
        .filter((key) => key.startsWith('name')) // Filters keys that start with 'name'
        .sort((a, b) => a.localeCompare(b)) // Order path based on the level
        .map((key) => item[key]) // Get the values (names)
      if (lastValue) {
        return textValue[textValue.length - 1] || ''
      }
      return textValue.join(
        ' <span class="font-weight-medium body-1"> &gt; </span> '
      )
    },
    selectOption(option) {
      const clearOption = { ...this.clarObject(option) }
      this.filteredOptions = [] // Clear the suggestions
      this.selectedPath = [] // Clear the path selected
      // Build the dynamic path based on the nameN keys
      this.selectedPath.push({ ...this.convertToTree(clearOption) })
      this.$emit('input', clearOption)
    },
    convertToTree(obj) {
      // Filtrar las claves que empiezan con 'id' y ordenarlas según el número al final de la clave
      const keys = Object.keys(obj)
        .filter((key) => key.startsWith('id'))
        .sort((a, b) => {
          // Extraer los números de las claves 'id1', 'id2', etc.
          const numA = parseInt(a.replace('id', ''))
          const numB = parseInt(b.replace('id', ''))
          return numA - numB
        })

      let tree = null // Nodo raíz
      let currentNode = null // Nodo actual

      for (let i = 0; i < keys.length; i++) {
        const id = parseInt(obj[keys[i]]) // Obtiene el valor del id como número
        const name = obj[`name${i + 1}`] // Obtiene el valor del name correspondiente
        const newNode = {
          id,
          name: `${name}`,
          children: [],
        }

        if (!tree) {
          // Si el árbol no existe, inicializarlo
          tree = newNode
          currentNode = tree
        } else {
          // Si el árbol ya existe, añadir el nuevo nodo como hijo
          currentNode.children.push(newNode)
          currentNode = newNode // Actualizar el nodo actual
        }
      }

      return tree
    },
    async fetchJsonFromStorage() {
      try {
        const self = this
        if (
          this.obj.schema.selectedCatalogs &&
          this.obj.schema.selectedCatalogs.length > 0
        ) {
          // Get url of download
          const storageRef = self.$fireModule
            .storage()
            .ref(`/catalogs/${this.obj.schema.selectedCatalogs[0].id}.json`)
          const url = await storageRef.getDownloadURL()
          // fetch to get data
          const response = await fetch(url)
          if (!response.ok) throw new Error('Error al obtener el archivo JSON')
          const jsonData = await response.json()
          // Update options for template
          this.options = jsonData
        } else {
          // TODO: Show alert to citizen
          console.log('No hay selección de catalogo en la configuración')
        }
      } catch (error) {
        // TODO: Show alert to citizen
        console.error('Error al obtener el archivo JSON desde Storage:', error)
      }
    },
  },
}
