
import Vue from 'vue'
import VueQrcode from 'vue-qrcode'
import { mapGetters } from 'vuex'

import { Certificate } from '@/services/Certificates'

export default Vue.extend({
  name: 'MiniQrRegister',
  components: { VueQrcode },
  props: {},
  data() {
    return {
      certificate: {} as Certificate,
      refCert: true,
      data: Object(),
      timerQr: true,
      playstore_link: '',
      fullUser: {},
      connector: Object as any,
      address: '',
      functionsURL: process.env.OSCITY_ENDPOINTS_URL,
      cityName: process.env.CITY_NAME,
      timerEnabled: false,
      timerCount: 10,
      certificateTimeout: setTimeout(() => {}, 0),
      projectId: process.env.FB_PROJECT_ID,
    }
  },
  computed: {
    widthGooglePlay(): string {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return '60%'
        case 'lg':
          return '80%'
        default:
          return '100%'
      }
    },
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      certsConfig: 'certsConfig/certsConfig',
    }),
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--
        }, 1000)
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true,
    },
  },
  async mounted() {},
  methods: {},
})
