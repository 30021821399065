import { setDocFirestore } from '@/services/firebaseWrite'

// Saves multiple filter documents in batch, adding additional metadata (timestamps, user info).
export const saveBatchFilters = async (
  $fire: any,
  firebase: any,
  docs: [],
  collection: string,
  userInfo: any
) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: any) => {
    doc.createdAt = firebase.firestore.Timestamp.now()
    doc.catalogCreatedAt = !doc.catalogCreatedAt
      ? doc.created
      : doc.catalogCreatedAt
    doc.catalogId = !doc.catalogId ? doc.id : doc.catalogId

    doc.createdByUid = userInfo.createdByUid
    doc.createdByEmail = userInfo.createdByEmail
    doc.logChanges = true

    delete doc.created
    delete doc.createdDate
    delete doc.filterCreatedAt
    delete doc.id

    const docRef = $fire.firestore.collection(collection).doc()
    batch.set(docRef, doc)
  })
  return await batch.commit()
}

// Deletes multiple filter documents in batch after updating them with deletion metadata (who deleted it).
export const deleteBatchFilters = async (
  $fire: any,
  firebase: any,
  docs: any,
  collection: string,
  userInfo: any
) => {
  const batch = $fire.firestore.batch()
  // Primero los actualizamos para agregar quien lo eliminó
  const dataToUpdate = {
    updatedByUid: userInfo.createdByUid,
    updatedByEmail: userInfo.createdByEmail,
    updatedAt: firebase.firestore.Timestamp.now(),
    isDelete: true,
  }
  for (let i = 0; i < docs.length; i++) {
    const doc = docs[i]
    const docId = doc.id
    await updateFilter($fire, docId, dataToUpdate)
  }
  docs.forEach((doc: any) => {
    const docRef = $fire.firestore.collection(collection).doc(doc.id)
    batch.delete(docRef)
  })
  return await batch.commit()
}

// Retrieves all filter documents from Firestore.
export const getFilters = async ($fire: any) => {
  return await $fire.firestore.collection('filters').get()
}

// Updates an existing filter document by ID.
export const updateFilter = async ($fire: any, id: string, body: any) => {
  return await setDocFirestore($fire, `filters/${id}`, { ...body }, true)
}

// Deletes a specific filter document by ID.
export const deleteFilter = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('filters').doc(id).delete()
}

// Deletes filters associated with a specific catalog, updating them with user info before deletion.
export const deleteFilterFromCatalogs = async (
  $fire: any,
  id: string,
  userInfo: any
) => {
  return await $fire.firestore
    .collection('filters')
    .where('catalogId', '==', id)
    .get()
    .then(async (querySnapshot: any) => {
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i]
        const docId = doc.id
        await updateFilter($fire, docId, userInfo)
        await doc.ref.delete()
      }
    })
}
