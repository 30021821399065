import { setDocFirestore } from '@/services/firebaseWrite'

// Retrieves the certificate configuration settings from Firestore.
export const getCertsConfig = async ($fire: any) => {
  return await $fire.firestore.collection('settings').doc('certsConfig').get()
}

// Updates the certificate configuration settings in Firestore.
export const updateCertsConfig = async ($fire: any, body: any) => {
  return await setDocFirestore($fire, `settings/certsConfig`, { ...body }, true)
}
