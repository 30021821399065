import { getFunctions, httpsCallable } from 'firebase/functions'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { isTokenExpired } from '@/services/Utils'

// Set the region from environment variables or default to 'us-central1'
const region = process.env.REGION || 'us-central1'

// Get the base URL for IDE-related API endpoints from environment variables
const urlIDE = process.env.URL_API_IDE
const urlIDERegistro = process.env.URL_API_IDE_REGISTRO

// Function to interact with IDE API (using Firebase cloud functions)
const ide = async (config: any) => {
  // Construct the request body with method type and API URL
  let body = {
    type: config.method, // HTTP method (GET, POST, etc.)
    url: `${urlIDE}/${config.url}`,
  }

  // If additional data is provided in the config, include it in the body
  if (config.data) {
    body = Object.assign({ data: config.data }, body)
  }

  // Get Firebase functions instance for interacting with cloud functions
  const functions = getFunctions(getApp(), region)

  // Call the Firebase cloud function that handles the IDE API request
  const ide = httpsCallable(functions, 'mty-instances-ide')

  // Execute the function and return the response data
  return await ide({
    ...body,
  })
    .then((response: any) => {
      // If the response contains data, return it
      if (response.data && response.data.data) return response.data.data
    })
    .catch((error) => {
      // Log error if the request fails
      console.error('An error occurred')
      throw new Error(error) // Throw error to be handled by the caller
    })
}

// Function to interact with Carto API (related to IDE and parking data)
const ideApiCarto = async (config: any) => {
  // Construct the request body for the Carto API request
  const body = {
    params: config.data, // Parameters for the API request
    type: 'GET',
    url: `https://admin.ec.appsmty.gob.mx/apiCarto/pdu/estacionamientosPorUso`, // Carto API URL
  }

  // Get Firebase functions instance
  const functions = getFunctions(getApp(), region)

  // Call the Firebase cloud function that handles the Carto API request
  const ide = httpsCallable(functions, 'mty-instances-ide')

  // Execute the function and return the response data
  return await ide({
    ...body, // Send the constructed body to the cloud function
  })
    .then((response: any) => {
      // If the response contains data, return it
      if (response.data && response.data.data) return response.data.data
    })
    .catch((error) => {
      // Log error if the request fails
      console.error('An error occurred')
      throw new Error(error) // Throw error to be handled by the caller
    })
}

// Function to interact with MIDE (IDE Registro) API
const ideMIDE = async (config: any) => {
  // Construct the request body for the MIDE (Registro) API request
  let body = {
    type: config.method, // HTTP method (GET, POST, etc.)
    url: `${urlIDERegistro}/registro-tramite-${config.url}/`, // Full API URL for MIDE
  }

  // If additional data is provided in the config, include it in the body
  if (config.data) {
    body = Object.assign({ data: config.data }, body)
  }

  // If the code is running in the browser (client-side)
  if (process.browser) {
    // Retrieve the user token from Vuex store or local storage
    const userToken =
      window.$nuxt.$store.getters['users/user'].token ||
      localStorage.getItem('mtyuserToken')

    // If a token exists, check if it has expired
    if (userToken) {
      const isAccesstokenExpired = isTokenExpired(userToken)

      // If the token is expired, refresh it; otherwise, use the existing token
      const tokenToUse = isAccesstokenExpired
        ? await getAuth().currentUser?.getIdToken(true) // Get a refreshed token
        : userToken

      // Add the token to the request body
      body = Object.assign({ token: tokenToUse }, body)
    }
  }

  // Get Firebase functions instance
  const functions = getFunctions(getApp(), region)

  // Call the Firebase cloud function that handles the MIDE API request
  const ideFunction = httpsCallable(functions, 'mty-instances-ide')

  // Execute the function and return the response data
  return await ideFunction({
    ...body, // Send the constructed body to the cloud function
  })
    .then((response: any) => {
      // If the response contains data, return it
      if (response.data && response.data.data) {
        return response.data
      } else if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      // Log error if the request fails
      console.error('An error occurred')
      throw new Error(error) // Throw error to be handled by the caller
    })
}

// Export the API interaction functions
export default { ide, ideApiCarto, ideMIDE }
