import { setDocFirestore } from '@/services/firebaseWrite'

// Retrieves the 'brand' document from the 'settings' collection.
export const getBrand = async ($fire: any) => {
  return await $fire.firestore.collection('settings').doc('brand').get()
}

// Updates the 'brand' document in the 'settings' collection.
export const updateBrand = async ($fire: any, body: any) => {
  return await setDocFirestore($fire, `settings/brand`, { ...body }, true)
}

// TODO: Should it be here? Or should it be in the modules services?
// Updates a specific document in the 'modules' collection based on module and submodule.
export const updatePostsListBrand = async (
  $fire: any,
  module: string,
  submodule: any,
  body: any
) => {
  return await setDocFirestore(
    $fire,
    `modules/${module}/${submodule}/settings`,
    { ...body },
    true
  )
}
