export const state = () => ({
  collaborator: [],
})

export const mutations = {
  SET_COLLABORATOR(state, collaborator) {
    state.collaborator = collaborator
  },
}

export const actions = {
  async get({ commit, rootState }) {
    if (rootState.user.uid) {
      await this.$fire.firestore
        .collectionGroup('collaborators')
        .where('uid', '==', rootState.user.uid)
        .get()
        .then((response) => {
          if (!response.empty) {
            this.items = response.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          }
        })
        .finally(() => {
          commit('SET_COLLABORATOR', this.items)
        })
    }
  },
}

export const getters = {}
