import InstanceIde from '@/services/apis/ide'

// Retrieves the "Clave Uso" for a specific "expediente" (file) using a reCAPTCHA token.
export const getClaveUsoPorExpediente = async (
  $axios: any,
  gtoken: string,
  expediente: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/uso-suelo-expediente/',
    data: {
      recaptcha_token: gtoken,
      expediente,
    },
    method: 'POST',
  })
}

// Retrieves the "Clave Uso" based on latitude and longitude coordinates using a reCAPTCHA token.
export const getClaveUsoPorLatLng = async (
  $axios: any,
  gtoken: string,
  latitud: number,
  longitud: number
) => {
  return await InstanceIde.ide({
    url: 'pdu/uso-suelo-coordenadas/',
    data: {
      recaptcha_token: gtoken,
      latitud,
      longitud,
    },
    method: 'POST',
  })
}

// Retrieves the business types ("giros") for a specific "clave uso" (land use key) using a reCAPTCHA token.
export const getGirosPorUso = async (
  $axios: any,
  gtoken: string,
  claveUso: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/giro-por-uso/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
    },
    method: 'POST',
  })
}

// Retrieves residential density ("densidad habitacional") for a given "clave uso" and "clave densidad" (density key).
export const getDensidadHabitacional = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  claveDensidad: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-habitacional/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      clave_densidad: claveDensidad,
    },
    method: 'POST',
  })
}

// Retrieves commercial and service density ("densidad comercial y servicio") for a given "clave uso".
export const getDensidadComercialesServicios = async (
  $axios: any,
  gtoken: string,
  claveUso: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-comercial-servicio/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
    },
    method: 'POST',
  })
}

// Retrieves controlled growth density ("densidad crecimiento controlado") for a given "clave uso" and pendiente (pending value).
export const getDensidadCrecimientoControlado = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  pendiente: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-crecimiento-controlado/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      pendiente,
    },
    method: 'POST',
  })
}

// Retrieves urban corridor density ("densidad corredores urbanos") for a given "clave uso".
export const getDensidadCorredoresUrbanos = async (
  $axios: any,
  gtoken: string,
  claveUso: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/corredor-urbano/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
    },
    method: 'POST',
  })
}

// Retrieves urban corridor density by area ("densidad corredores urbanos area") for a given "clave uso" and area.
export const getDensidadCorredoresUrbanosArea = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  area: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/corredor-urbano-area/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      area,
    },
    method: 'POST',
  })
}

// Retrieves suburban center density ("densidad subcentros urbanos") by area for a given "clave uso".
export const getDensidadSubcentrosUrbanos = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  area: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-subcentro-urbano/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      area,
    },
    method: 'POST',
  })
}

// Retrieves conservation zone ("zona conservación") based on latitude and longitude coordinates using a reCAPTCHA token.
export const getZonaConservacion = async (
  $axios: any,
  gtoken: string,
  latitud: number,
  longitud: number
) => {
  return await InstanceIde.ide({
    url: 'pdu/zona-conservacion/',
    data: {
      recaptcha_token: gtoken,
      latitud,
      longitud,
    },
    method: 'POST',
  })
}
