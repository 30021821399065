import { getAuthConfig } from '@/services/AuthConfig'

export const state = () => ({
  databaseLoad: null,
  authconfig: {
    autenticar: false,
    createNewAdmin: false,
    email: true,
    did: false,
    mobile: false,
    wallet: false,
    register: true,
    withAnalytics: false,
    redirect: '',
    oldRegister: null,
    web3: true,
    procedureRatings: false,
    sso: false,
    enableRatings: false,
    withContact: true,
    useOnlyProcedurePost: false,
    useTermsDoc: '',
    usePrivPolicyDoc: '',
  },
})

export const mutations = {
  SET_LOGIN_TYPES(state, authconfig) {
    state.authconfig = authconfig
  },
  SET_DATABASE(state, authconfig) {
    state.databaseLoad = authconfig
  },
}

export const actions = {
  async get({ commit, rootState }) {
    // const self = this
    await getAuthConfig(this.$fire).then((res) => {
      commit('SET_DATABASE', true)
      let authconfig = rootState.authconfig.authconfig
      if (res.exists === true) {
        authconfig = res.data()
        commit('SET_LOGIN_TYPES', authconfig)
      }
    })
  },
}

export const getters = {
  autenticar(state) {
    return state.authconfig.autenticar
  },
  email(state) {
    return state.authconfig.email
  },
  mobile(state) {
    return state.authconfig.mobile
  },
  wallet(state) {
    return state.authconfig.wallet
  },
  register(state) {
    return state.authconfig.register
  },
  isDID(state) {
    return state.authconfig.did
  },
  databaseLoad(state) {
    return state.databaseLoad
  },
  withAnalytics(state) {
    return state.authconfig.withAnalytics
  },
  createNewAdmin(state) {
    return state.authconfig.createNewAdmin
  },
  getRedirect(state) {
    return state.authconfig.redirect
  },
  getOldRegister(state) {
    return state.authconfig.oldRegister
  },
  withWeb3(state) {
    return state.authconfig.web3
  },
  getsso(state) {
    return state.authconfig.sso
  },
  getonlyProcedurePost(state) {
    return state.authconfig.useOnlyProcedurePost
  },
  getEnableRatings(state) {
    return state.authconfig.enableRatings
  },
  withContact(state) {
    return state.authconfig.withContact
  },
  getuseTermsDoc(state) {
    return state.authconfig.useTermsDoc
  },
  getuseusePrivPolicyDoc(state) {
    return state.authconfig.usePrivPolicyDoc
  },
}
